import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import backgroundImage from '../../../../assets/images/qeerio/cityview2.jpg';
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    // background: 'linear-gradient(90deg, rgba(255,100,83,1) 0%, rgba(25,25,25,1) 80%)',
    // borderRadius: '0 0 0% 0',
    // minHeight: 400
    // overflow: 'hidden',
  },
  image: {
    opacity: 0.6,
    maxHeight: 400,
    objectFit: 'cover',
    // [theme.breakpoints.down('sm')]: {
    //   width: 'auto',
    // },
    // borderRadius: '0 0 100% 0'
    // clipPath: 'polygon(0 0, 82% 0, 70% 73%, 0 73%);'
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <StaticImage
        src='../../../../assets/images/qeerio/cityview2.jpg'
        srcSet='../../../../assets/images/qeerio/cityview2.jpg'
        alt="Support"
        lazy
        placeholder="blurred"
        className={classes.image}
        lazyProps={{
          width: '100%',
          height: '100%'
        }}
      />
      <Section className={classes.section}>
        <SectionHeader
          title="Can't find the answer you need?"
          subtitle="Select your best way to contact us!"
          align="left"
          data-aos="fade-up"
          disableGutter
          titleProps={{
            className: clsx(classes.title, classes.textWhite),
            variant: 'h3',
          }}
          subtitleProps={{
            className: classes.textWhite,
          }}
        />
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
